import revive_payload_client_4sVQNw7RlN from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9ynfHYDVid from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/@graphiboxdev/graphi-toolbox/dist/runtime/plugin.mjs";
import pinia_ujspKtKF4T from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/node_modules/@graphiboxdev/graphi-toolbox/dist/runtime/pinia.mjs";
import directives_8CcCirWtnE from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/plugins/directives.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_9ynfHYDVid,
  pinia_ujspKtKF4T,
  directives_8CcCirWtnE
]